import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Button, Navbar, Spinner, Modal, Carousel, ModalHeader } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import apinode from './apinode';
import './App.css'; // Importa o arquivo CSS para os estilos responsivos

const ProductCatalog = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showFOB, setShowFOB] = useState(false);

    useEffect(() => {
        apinode.get('/estoque/catalogo/000000001')
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handleShow = (index) => {
        setActiveIndex(index);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const handleShowFOB = () => setShowFOB(true);
    const handleCloseFOB = () => setShowFOB(false);

    const calculateFOBPrices = (product) => {
        return {
            ...product,
            precovenda: product.precovenda * 1.12,
            precoatacado: product.precoatacado * 1.12,
        };
    };

    const filteredProducts = products.filter(product =>
        product.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.codinterno?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.ean?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredProductsFOB = filteredProducts.map(calculateFOBPrices);

    const handleExport = () => {
        const worksheetCIF = XLSX.utils.json_to_sheet(filteredProducts.map(product => ({
            Ref: product.codinterno,
            SKU: product.sku,
            EAN: product.ean,
            Descrição: product.descricao,
            'Preço Varejo (FOB)': product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
            'Preço Atacado (FOB)': product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
        })));

        const worksheetFOB = XLSX.utils.json_to_sheet(filteredProductsFOB.map(product => ({
            Ref: product.codinterno,
            SKU: product.sku,
            EAN: product.ean,
            Descrição: product.descricao,
            'Preço Varejo (CIF)': product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
            'Preço Atacado (CIF)': product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheetFOB, 'Products FOB');
        XLSX.utils.book_append_sheet(workbook, worksheetCIF, 'Products CIF');
        XLSX.writeFile(workbook, 'Products.xlsx');
    };

    const handlePrintPDF = () => {
        setLoading(true);
        if (isMobile()) {
            handlePrintPDFMobile('catalogo');
        }
    
        html2canvas(document.getElementById('catalog'), {
            scale: 0.8, // Lower the scale for better performance
            useCORS: true,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 0.3);
    
            const pdfWidth = 595.28;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    
            const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
    
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
            const pdfBlob = pdf.output('blob');
            const pdfURL = URL.createObjectURL(pdfBlob);
    
            window.open(pdfURL);
    
            uploadPDF(pdfBlob, 'catalogo.pdf');
            setLoading(false);
        }).catch(error => {
            console.error('Error generating PDF: ', error);
            setLoading(false);
            alert('Houve um problema ao gerar o PDF. Por favor, tente novamente.');
        });
    };
    

    const handlePrintFOB = () => {
        setLoading(true);
        if (isMobile()) {
            handlePrintPDFMobile('catalogo_fob');
        } else {
            setLoading(true);

            html2canvas(document.getElementById('catalogFOB'), {
                scale: 1,
                useCORS: true,
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 0.3);

                const pdfWidth = 595.28;
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

                const pdfBlob = pdf.output('blob');
                const pdfURL = URL.createObjectURL(pdfBlob);

                window.open(pdfURL);

                uploadPDF(pdfBlob, 'catalogo_fob.pdf');
                setLoading(false);
            }).catch(error => {
                console.error('Error generating PDF: ', error);
                setLoading(false);
                alert('Houve um problema ao gerar o PDF. Por favor, tente novamente.');
            });
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 768; // or use any other condition to detect mobile devices
    };



    const handlePrintPDFMobile = (tipo) => {
        setLoading(true);

        apinode.get(`/assets/${tipo}.pdf`, { responseType: 'blob' })
            .then(response => {
                const fileURL = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'catalogo.pdf');
                document.body.appendChild(link);

                setTimeout(() => {
                    link.click();
                    document.body.removeChild(link);
                    setLoading(false);
                }, 100);
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                setLoading(false);
                alert('Houve um problema ao obter o PDF. Por favor, tente novamente.');
            });
    };

    const uploadPDF = (pdfBlob, fileName) => {
        const formData = new FormData();
        formData.append('file', pdfBlob, fileName);

        apinode.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log('Arquivo enviado com sucesso:', response.data);
                alert('PDF enviado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao enviar o arquivo:', error);
                alert('Houve um problema ao enviar o PDF. Por favor, tente novamente.');
            });
    };

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="BelaLumen_Preto.png"
                        width="200"
                        height="90"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
            </Navbar>
            <div className="container mt-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1>Tabela de Produtos</h1>
                    <div className="d-flex flex-wrap">
                        <Button variant="primary" onClick={handlePrintPDF} disabled={loading} className="me-2 mb-2">
                            {loading ? (
                                <>
                                    <Spinner animation="border" size="sm" /> Carregando...
                                </>
                            ) : (
                                'Baixar PDF FOB'
                            )}
                        </Button>
                        <Button onClick={handleExport} className="btn btn-warning me-2 mb-2">
                            Exportar para Excel
                        </Button>
                        <Button variant="success" onClick={handleShowFOB} className="mb-2">
                            Visualizar Tabela CIF
                        </Button>
                    </div>
                </div>

                <input
                    type="text"
                    placeholder="Pesquisar..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="form-control mb-3"
                />

                <div className="table-responsive">
                    <table id="catalog" className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Ref.</th>
                                <th>SKU</th>
                                <th>EAN</th>
                                <th>Descrição</th>
                                <th>Preço Varejo</th>
                                <th>Preço Atacado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map((product, index) => (
                                <tr key={product.id}>
                                    <td>
                                        <img
                                            src={`https://node.ziphub.com.br/imagens/${product.img_path}`}
                                            alt={product.codinterno}
                                            style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }}
                                            onClick={() => handleShow(index)}
                                        />
                                    </td>
                                    <td>{product.codinterno}</td>
                                    <td>{product.sku}</td>
                                    <td>{product.ean}</td>
                                    <td>{product.descricao}</td>
                                    <td>
                                        {product.precovarejo_p > 0 ? (
                                            <span className="badge bg-warning text-dark">R${product.precovarejo_p.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                        ) : (
                                            <span>R${product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                        )}
                                    </td>
                                    <td>
                                        {product.precoatacado_p > 0 ? (
                                            <span className="badge bg-warning text-dark">R${product.precoatacado_p.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                        ) : (
                                            <span>R${product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body>
                    <Carousel activeIndex={activeIndex} onSelect={() => { }}>
                        {filteredProducts.map(product => (
                            <Carousel.Item key={product.id}>
                                <img
                                    className="d-block w-100"
                                    src={`https://node.ziphub.com.br/imagens/${product.img_path}`}
                                    alt={product.descricao}
                                />
                                {/* <Carousel.Caption>
                                    <h3>{product.descricao}</h3>
                                    <p>Ref: {product.codinterno} | SKU: {product.sku} | EAN: {product.ean}</p>
                                    <p>Preço Varejo: R${product.precovenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                    <p>Preço Atacado: R${product.precoatacado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                </Carousel.Caption> */}
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>

            <Modal show={showFOB} onHide={handleCloseFOB} centered size="lg">
                <Modal.Body>
                    <Modal.Header>
                        <Modal.Title>Tabela CIF</Modal.Title>
                        <div className="ms-auto">
                            <Button onClick={handlePrintFOB} variant="primary">
                                {loading ? (
                                    <>
                                        <Spinner animation="border" size="sm" /> Carregando...
                                    </>
                                ) : (
                                    'Baixar PDF CIF'
                                )}
                            </Button>
                        </div>
                    </Modal.Header>
                    <div className="table-responsive">
                        <table id="catalogFOB" className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Ref.</th>
                                    <th>SKU</th>
                                    <th>EAN</th>
                                    <th>Descrição</th>
                                    <th>Preço Varejo CIF</th>
                                    <th>Preço Atacado CIF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProductsFOB.map((product, index) => (
                                    <tr key={product.id}>
                                        <td>
                                            <img
                                                src={`https://node.ziphub.com.br/imagens/${product.img_path}`}
                                                alt={product.codinterno}
                                                style={{ maxWidth: '50px', maxHeight: '50px' }}
                                            />
                                        </td>
                                        <td>{product.codinterno}</td>
                                        <td>{product.sku}</td>
                                        <td>{product.ean}</td>
                                        <td>{product.descricao}</td>
                                        <td>
                                            {product.precovarejo_p > 0 ? (
                                                <span className="badge bg-warning text-dark">
                                                    R${(product.precovarejo_p * 1.12 ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            ) : (
                                                <span>
                                                    R${(product.precovenda  ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {product.precoatacado_p > 0 ? (
                                                <span className="badge bg-warning text-dark">
                                                    R${(product.precoatacado_p * 1.12  ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            ) : (
                                                <span>
                                                    R${(product.precoatacado ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            )}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProductCatalog;
